// Purpose: Some components can be clicked and thereby closed with JS
function remove(el) {
  el.parentNode.removeChild(el);
}

export default function attachDismissable() {
  const dismissables = document.querySelectorAll("[data-dismiss]");

  dismissables.forEach((triggerEl) => {
    const elToRemove = document.getElementById(triggerEl.dataset.dismiss);
    triggerEl.addEventListener("click", () => remove(elToRemove));
  });
}
