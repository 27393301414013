import analytics from "./analytics"
import directUploads from "./directUploads"
import discounts from "./discounts"
import dismissable from "./dismissable"
import readMore from "./readMore"
import remoteForms from "./remoteForms"
import selectBestNotesFile from "./selectBestNotesFile"
import trackFileDownload from "./trackFileDownload"
import tutors from "./tutors"

document.addEventListener("DOMContentLoaded", () => {
  analytics()
  directUploads()
  discounts()
  dismissable()
  readMore()
  remoteForms()
  selectBestNotesFile()
  tutors()
  trackFileDownload()
})
