export default function () {
  document.querySelectorAll("[data-deletion-link]").forEach((link) => {
    // This relies on the events Rails AJAX sends, such as ajax::success and
    // ajax:error
    link.addEventListener("ajax:success", () => {
      link.parentNode.removeChild(link)
    })

    link.addEventListener("ajax:error", () => {
      link.classList.add("flash")
      link.classList.add("error")
      link.innerText = `Error: Could not delete "${link.innerText}"`
    })
  })
}
