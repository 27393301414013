const trackDownload = function() {
  const item = this
  // This is needed to get Rails to understand the params
  const payload = new URLSearchParams({
    name: item.dataset.name,
    original_item_id: item.dataset.originalItemId,
    original_item_type: item.dataset.originalItemType
  })

  fetch("/track_file_download", {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
    },
    body: payload
  }).then(() => {
    item.innerText = item.innerText + " - Accessed"
    item.classList.add("tracked-download-box")
  })
}

export default function() {
  const downloadLinks = document.querySelectorAll(
    "a.download_link_for_tracking"
  )

  downloadLinks.forEach(downloadLink => {
    downloadLink.addEventListener("click", trackDownload, { once: true })
    downloadLink.addEventListener("contextmenu", trackDownload, { once: true })
  })
}
