import { callOnDataAttribute, isLoggedIn, getJSON } from "./library"

export default function showDiscountedPrice() {
  if (isLoggedIn()) {
    callOnDataAttribute("discounted-price", (priceEl) => {
      const productId = priceEl.dataset.productId
      const normalPrice = priceEl.innerText
      const currencySymbol = normalPrice[0]

      getJSON(
        `/discounts/${productId}`,
        ({ discountAvailable, discountedPrice }) => {
          if (!discountAvailable) {
            return
          }
          priceEl.innerText = currencySymbol + discountedPrice + "(normally " + normalPrice + " but / discounted based on your purchase history)"
        }
      )
    })
  }
}
