import { displayDoc } from "./docPreview"

function BestNotesFileSelector(candidatesElement) {
  //console.log(candidatesElement.dataset)
  const candidates = JSON.parse(
    candidatesElement.dataset.bestNotesFileCandidates
  )
  let currentCandidateIndex = 0

  document.addEventListener("keydown", respondToKeyDown)

  function respondToKeyDown(event) {
    event.preventDefault()

    const leftArrowKey = 37
    const rightArrowKey = 39
    const downArrowKey = 40

    if (event.key == leftArrowKey) {
      chooseCandidateByIndex(currentCandidateIndex - 1)
    } else if (event.key == rightArrowKey) {
      chooseCandidateByIndex(currentCandidateIndex + 1)
    } else if (event.key == downArrowKey) {
      chooseCandidateByIndex(cycleExamYearIndex())
    }
  }

  function cycleExamYearIndex() {
    const currentExamYear = candidates[currentCandidateIndex].exam_year
    const atMaxExamYear =
      currentExamYear == Math.max(...candidates.map(el => el.exam_year))

    return candidates.findIndex(el => {
      if (atMaxExamYear) {
        return true
      } else {
        return el.exam_year > currentExamYear
      }
    })
  }

  function chooseCandidateByIndex(i) {
    // handle negative numbers
    currentCandidateIndex =
      ((i % candidates.length) + candidates.length) % candidates.length
    const currentCandidate = candidates[currentCandidateIndex]

    document.querySelector("[data-best-notes-file-name]").textContent =
      currentCandidate["name"]
    document.querySelector("[data-best-notes-file-exam-year]").textContent =
      currentCandidate["exam_year"]
    document.querySelector("[data-best-notes-file-page-count]").textContent =
      currentCandidate["page_count"]
    document.querySelector("[data-best-notes-file-id]").value =
      currentCandidate["id"]

    displayDoc(currentCandidate["url"], currentCandidate["filetype"])
  }
}

export default function() {
  const candidatesElement = document.querySelector(
    "[data-best-notes-file-candidates]"
  )
  if (!candidatesElement) {
    return
  }
  BestNotesFileSelector(candidatesElement)
}
