// TODO: delete

import PDFObject from "pdfobject";

export function displayDoc(url, fileType = "pdf") {
  if (fileType != "pdf") {
    renderWithMSOffice(url);
  } else if (PDFObject.supportsPDFs) {
    PDFObject.embed(url, docPreviewer(), {
      pdfOpenParams: {
        view: "Fit",
        scrollbars: "0",
        toolbar: "0",
        statusbar: "0",
        navpanes: "0"
      }
    });
    // hack until fixed https://github.com/pipwerks/PDFObject/issues/173
    document.getElementsByClassName("pdfobject")[0].src = url;
  } else {
    renderWithGoogleViewer(url);
  }
}

// only works with MS file formats
function renderWithMSOffice(url) {
  renderEmbed(
    `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
      url
    )}`
  );
}

// only works with PDFs
function renderWithGoogleViewer(url) {
  renderEmbed(
    `https://docs.google.com/viewer?url=${encodeURIComponent(
      url
    )}&embedded=true`
  );
}

/**
 *
 *
 */
function renderEmbed(viewerUrl) {
  const isMobile = window.matchMedia(
    "only screen and (max-width: 480px)"
  ).matches;

  const iframe = document.createElement("iframe");
  iframe.src = viewerUrl;
  iframe.title = docPreviewer().dataset.title;
  iframe.loading = "lazy";

  // lmao ok what
  while (docPreviewer().firstChild) {
    docPreviewer().removeChild(docPreviewer().firstChild);
  }

  docPreviewer().appendChild(iframe);
}

function docPreviewer() {
  const embedId = "pdf-preview__wrapper";
  return document.getElementById(embedId);
}

export default function docPreview() {
  if (!docPreviewer()) {
    return;
  }

  displayDoc(docPreviewer().dataset.docUrl, docPreviewer().dataset.filetype);
}
