import { configFor } from "./library"

export default function() {
  const directUploader = document.getElementById("direct_uploader")
  if (directUploader === null) {
    return
  }

  const fileInput = document.querySelector('input[type="file"]')

  fileInput.addEventListener("change", event => {
    const { files } = event.target
    if (!validateBeforeSend(files)) {
      // Remove the files
      fileInput.value = ""
    } else {
      const submitInput = document.getElementById("direct_uploader_finalize")

      files.forEach(file => {
        submitInput.insertAdjacentHTML(
          "beforebegin",
          `
            <div id="direct-upload-${file.name}" class="direct-upload direct-upload--pending">
              <div id="direct-upload-progress-${file.name}" class="direct-upload__progress" style="width: 0%"></div>
              <span class="direct-upload__filename"> ${file.name}</span>
            </div>
          `
        )
      })
      submitInput.classList.remove("layout__hide")
    }
  })

  addEventListener("direct-upload:progress", event => {
    const { file, progress } = event.detail
    const progressElement = document.getElementById(
      `direct-upload-progress-${file.name}`
    )
    progressElement.style.width = `${progress}%`
  })

  // As each file gets completed
  addEventListener("direct-upload:end", event => {
    const { file } = event.detail
    const element = document.getElementById(`direct-upload-${file.name}`)
    element.classList.add("direct-upload--complete")
  })

  addEventListener("direct-upload:error", event => {
    const { file, error } = event.detail
    const element = document.getElementById(`direct-upload-${file.name}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
    alert(file.name + " failed to upload : " + error)
  })
}

function validFiletypeRegex() {
  const acceptableFileExtensions = configFor(
    "settings.acceptableFileExtensions"
  )
  // eslint-disable-next-line
  return new RegExp(`\.(${acceptableFileExtensions})$`)
}

function checkFiletypeValid(filename) {
  // why inspect and no quotes? Because otherwise the ruby to javascript converter frigs up the regex
  if (filename.match(validFiletypeRegex())) {
    return true
  } else {
    return false
  }
}

function validateBeforeSend(files) {
  const { every } = Array.prototype

  const isValid = every.call(files, file => checkFiletypeValid(file.name))

  if (!isValid) {
    // FIXME: Message should differ depending on terms
    const message =
      "Sorry, we only accept popular file formats, such as Word, PDF, OpenOffice, Excel, etc."
    alert(message)
  }
  return isValid
}
