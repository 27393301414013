document.addEventListener('DOMContentLoaded', () => {
  let isLoading = false;

  const infiniteScroll = () => {
    if (isLoading) return;

    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.documentElement.scrollHeight - 100 - document.getElementById("footer").offsetHeight;

    if (scrollPosition >= threshold) {
      loadMorePosts();
    }
  };

  const loadMorePosts = async () => {
    const itemsContainer = document.querySelector('#items');
    if (!itemsContainer) return;

    isLoading = true;
    const spinner = document.getElementById('infinite-scroll-spinner');
    spinner.style.display = 'block';

    const nextPage = document.querySelector('#items').dataset.nextPage;

    // If there's no next page, stop the infinite scroll
    if (!nextPage) {
      spinner.style.display = 'none';
      window.removeEventListener('scroll', infiniteScroll);
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    try {
      const response = await fetch(nextPage, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });

      if (!response.ok) throw new Error('Failed to load more posts');
      const data = await response.json();
      const itemsContainer = document.querySelector('#items');

      itemsContainer.insertAdjacentHTML('beforeend', data.items.join(''));
      itemsContainer.dataset.nextPage = data.next_page;

      if (!data.next_page) {
        window.removeEventListener('scroll', infiniteScroll);
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading = false;
      spinner.style.display = 'none';
    }
  };

  window.addEventListener('scroll', infiniteScroll);
});
