function undeemphasizeDisciplineInput(el) {
  if (el.value == "1") {
    el.classList.remove("text--deemphasize")
  } else {
    el.classList.add("text--deemphasize")
  }
}

export default function() {
  const lawDisciplineSelectInputs = document.querySelectorAll(
    "[data-law-discipline]"
  )

  lawDisciplineSelectInputs.forEach(lawDisciplineSelectInput => {
    lawDisciplineSelectInput.addEventListener("change", event => {
      undeemphasizeDisciplineInput(event.target)
    })

    // Handle re-display of form with existing data (e.g. after errors)
    if (lawDisciplineSelectInput.value == "1") {
      undeemphasizeDisciplineInput(lawDisciplineSelectInput)
    }
  })
}
