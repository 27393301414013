export default function attachReadMore() {
  const hiddenContentWrappers = document.querySelectorAll(
    "[data-read-more-hidden-content]"
  )
  const toggleLinks = document.querySelectorAll("[data-toggle-read-more]")

  ;[].forEach.call(hiddenContentWrappers, function(hiddenContentWrapper) {
    hiddenContentWrapper.setAttribute("hidden", true)
  })
  ;[].forEach.call(toggleLinks, function(toggleLink) {
    toggleLink.addEventListener("click", function(event) {
      event.preventDefault()

      const hiddenContentWrapper = this.parentElement.parentElement.querySelector(
        "[data-read-more-hidden-content]"
      )

      // change attributes and text if full text is shown/hidden
      if (!hiddenContentWrapper.hasAttribute("hidden")) {
        toggleLink.innerText = "Show More >"
        hiddenContentWrapper.setAttribute("hidden", true)
        toggleLink.setAttribute("aria-expanded", false)
      } else {
        toggleLink.innerText = "Show Less"
        hiddenContentWrapper.removeAttribute("hidden")
        toggleLink.setAttribute("aria-expanded", true)
      }
    })
  })
}
